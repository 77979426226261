import * as dragula from 'dragula'

type ReorderingResult = {
    success: boolean;
}

class ReorderingContainer
{
    private wrapper: HTMLElement;
    private form: HTMLFormElement;
    private drake: dragula.Drake;

    constructor(wrapper: HTMLElement, form: HTMLFormElement)
    {
        this.wrapper = wrapper;
        this.wrapper.classList.add("reordering-wrapper");
        this.form = form;
        this.drake = dragula([this.wrapper]);
        this.drake.on("drop", (el, target, source, sibling) => this.dropElement(el, target, source, sibling))
    }

    private dropElement(el: Element, _target: Element, _source: Element, sibling: Element)
    {
        const itemId = (el as HTMLElement).dataset.itemId;
        if (!itemId)
        {
            return;
        }

        const targetId = sibling ? (sibling as HTMLElement).dataset.itemId : null;
        console.log(`drop ${itemId} before ${targetId ?? "end"}`);

        const formContent = new FormData(this.form);
        formContent.append("image", itemId);
        if (targetId)
        {
            formContent.append("target", targetId);
        }

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (ev: Event) => this.handleResult(xhr, ev);
        xhr.open(this.form.method, this.form.action);
        xhr.send(formContent);
        this.wrapper.classList.add("reordering-inprogress");
    }

    private handleResult(xhr: XMLHttpRequest, _ev: Event)
    {
        if (xhr.readyState !== XMLHttpRequest.DONE)
        {
            return;
        }

        const result = JSON.parse(xhr.responseText) as ReorderingResult;
        if (result.success)
        {
            this.wrapper.classList.remove("reordering-inprogress");
        }
        else
        {
            this.wrapper.classList.add("reordering-error");
        }
    }
}

const dragElems = document.querySelectorAll("[dbs-reorder]");
const form = document.getElementById("move-image");
if (form && form.tagName == "FORM")
{
    for (let i = 0; i < dragElems.length; i++)
    {
        new ReorderingContainer(dragElems[i] as HTMLElement, form as HTMLFormElement);
    }
}