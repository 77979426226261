import * as $ from 'jquery';
(global as any).ExtendedSelects as { [id: string]: any };

const controls = new Array<FileUploader>();
type FileUploadModel = {
    error: string | null,
    id: string | null,
    name: string | null,
    size: number | null,
    url: string | null;
}

class FileUploader
{
    private uploadButton: HTMLButtonElement;
    private selectElem: HTMLSelectElement;
    private rvtElem: HTMLInputElement;

    private launchFileUpload()
    {
        const input = document.createElement("input") as HTMLInputElement;
        input.type = "file";

        input.addEventListener("change",
            () =>
            {
                if (input.files)
                {
                    const item = input.files.item(0);
                    if (item != null)
                    {
                        this.setFile(item);
                    }
                }
            });

        setTimeout(() => input.click(), 0);
    }

    private setFile(file: File)
    {
        const formData = new FormData();
        formData.append("__RequestVerificationToken", this.rvtElem.value);
        formData.append("file", file, file.name);
        $.ajax({
            type: "POST",
            url: "/admin/files/upload/api/",
            data: formData as JQuery.PlainObject,
            cache: false,
            contentType: false,
            processData: false,
            success: (content: any) => this.loadSuccessfulUpload(content as FileUploadModel)
        })
    }

    private loadSuccessfulUpload(content: FileUploadModel)
    {
        const extendedSelect = (global as any).ExtendedSelects[this.selectElem.id];
        if (!extendedSelect)
        {
            return;
        }

        extendedSelect.addOption({
            value: content.id,
            text: `${content.name} (Now)`
        });

        extendedSelect.setValue([content.id]);
    }

    public constructor(elem: HTMLElement)
    {
        this.uploadButton = elem.querySelector("button[type=button]") as HTMLButtonElement;
        this.selectElem = elem.querySelector("select") as HTMLSelectElement;
        this.rvtElem = this.selectElem.form?.querySelector("input[name=__RequestVerificationToken]") as HTMLInputElement;

        this.uploadButton.addEventListener("click", () => this.launchFileUpload());
    }
}

function Initialise()
{
    const uploaders = document.querySelectorAll("[dbs-file-upload]");
    for (let i = 0; i < uploaders.length; i++)
    {
        const uploader = new FileUploader(uploaders[i] as HTMLElement);
        controls.push(uploader);
    }
}

window.addEventListener("load", Initialise);