const defaultZoom = 11;

let config: DraggableMapOptions;
let mapLat: HTMLInputElement;
let mapLng: HTMLInputElement;
let map: google.maps.Map;
let marker: google.maps.Marker;

// ReSharper disable InconsistentNaming
export interface DraggableMapOptions
{
    mapContainerId: string;
    mapLatitudeId: string;
    mapLongitudeId: string;
    previousId: string;
    resetId: string;
    calculatedLatLng: google.maps.LatLng;
    previousLatLng: google.maps.LatLng;
}
// ReSharper restore InconsistentNaming

function updateMarkerPositionFromFields()
{
    const newPosition = new google.maps.LatLng(parseFloat(mapLat.value), parseFloat(mapLng.value));
    moveMarker(newPosition);
}

function updateMarkerPosition(latLng: google.maps.LatLng)
{
    marker.setPosition(latLng);
    mapLat.value = latLng.lat().toFixed(5);
    mapLng.value = latLng.lng().toFixed(5);
}

function moveMarker(latLng: google.maps.LatLng)
{
    updateMarkerPosition(latLng);
    map.setZoom(defaultZoom);
    map.setCenter(latLng);
}

export default function (configParams: DraggableMapOptions)
{
    config = configParams;
    mapLat = document.getElementById(config.mapLatitudeId) as HTMLInputElement;
    mapLng = document.getElementById(config.mapLongitudeId) as HTMLInputElement;

    const mapElem = document.getElementById(config.mapContainerId) as HTMLElement;

    map = new google.maps.Map(mapElem,
        {
            zoom: defaultZoom,
            center: config.previousLatLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });

    marker = new google.maps.Marker({
        position: config.previousLatLng,
        title: "Location",
        map: map,
        draggable: true
    });

    google.maps.event.addListener(marker,
        "dragend",
        () =>
        {
            let position = marker.getPosition();
            if (position == null)
            {
                return;
            }

            updateMarkerPosition(position);
        });

    mapLat.addEventListener("change", updateMarkerPositionFromFields);
    mapLng.addEventListener("change", updateMarkerPositionFromFields);

    const previousBtn = document.getElementById(config.previousId) as HTMLElement;
    if (previousBtn != null)
    {
        previousBtn.addEventListener("click",
            (ev: Event) =>
            {
                ev.preventDefault();
                moveMarker(config.previousLatLng);
            });
    }

    const resetBtn = document.getElementById(config.resetId) as HTMLElement;
    if (resetBtn != null)
    {
        resetBtn.addEventListener("click",
            (ev: Event) =>
            {
                ev.preventDefault();
                moveMarker(config.calculatedLatLng);
            });
    }
}