//
// Checklist
//

'use strict';

var $ = require("jquery");
var Checklist = (function() {

	//
	// Variables
	//

	var $list = $('[data-toggle="checklist"]')


	//
	// Methods
	//

	// Init
	function init($this) {
		var $checkboxes = $this.find('.checklist-entry input[type="checkbox"]');

		$checkboxes.each(function() {
			checkEntry($(this));
		});

	}

	function checkEntry($checkbox) {
		if($checkbox.is(':checked')) {
			$checkbox.closest('.checklist-item').addClass('checklist-item-checked');
		} else {
			$checkbox.closest('.checklist-item').removeClass('checklist-item-checked');
		}
	}


	//
	// Events
	//

	// Init
	if ($list.length) {
		$list.each(function() {
			init($(this));
		});

		$list.find('input[type="checkbox"]').on('change', function() {
			checkEntry($(this));
		});
	}

})();
