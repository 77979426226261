let controls = new Array<MultiUploader>();

type DirectUploadResult = {
    id: string;
    url: string;
}

class MultiUploader
{
    private uploadName: string = "";
    private dropBox: Element = document.createElement("div");
    private queue: File[] = [];
    private xhr: XMLHttpRequest | null = null;

    private areaDragEnter(event: Event)
    {
        event.stopPropagation();
        event.preventDefault();
    }

    private areaDragLeave(event: Event)
    {
        event.stopPropagation();
        event.preventDefault();
    }

    private isDragEvent(ev: Event): ev is DragEvent
    {
        return (ev as DragEvent).dataTransfer !== undefined;
    }

    private areaDragOver(ev: Event)
    {
        if (!this.isDragEvent(ev) || ev.dataTransfer == null)
        {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();

        ev.dataTransfer.dropEffect = "copy";

        return false;
    }

    private areaDrop(ev: Event)
    {
        if (!this.isDragEvent(ev) || ev.dataTransfer == null)
        {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();

        if (ev.dataTransfer.files.length > 0)
        {
            return false;
        }

        this.queueUpload(ev.dataTransfer.files);

        return false;
    }

    private launchFileUpload()
    {
        const input = document.createElement("input") as HTMLInputElement;
        input.type = "file";
        input.multiple = true;

        input.addEventListener("change",
            () =>
            {
                if (input.files && input.files.length > 0)
                {
                    this.queueUpload(input.files);
                }
            });

        setTimeout(() => input.click(), 0);
    }

    private queueUpload(files: FileList)
    {
        for (let i = 0; i < files.length; i++)
        {
            const file = files.item(i);
            if (file == null)
            {
                continue;
            }

            this.queue.push(file);
        }

        this.processQueue();
    }

    private processQueue()
    {
        if (this.queue.length === 0)
        {
            return;
        }

        if (this.xhr == null || this.xhr.readyState === XMLHttpRequest.DONE)
        {
            this.xhr = new XMLHttpRequest();
            this.xhr.onreadystatechange = () => this.processUploadState();
            const file = this.queue.shift();
            if (!file)
            {
                return;
            }

            const data = new FormData();
            data.append("image", file);

            this.xhr.open("POST", "/api/images/direct-upload/");
            this.xhr.send(data);
        }
    }

    private processUploadState()
    {
        if (!this.xhr)
        {
            return;
        }

        if (this.xhr.readyState === XMLHttpRequest.DONE && this.xhr.status === 200)
        {
            const result = JSON.parse(this.xhr.responseText) as DirectUploadResult;
            if (result == null)
            {
                return;
            }

            const input = document.createElement("input");
            input.type = "text";
            input.name = this.uploadName;
            input.value = result.id;

            const img = document.createElement("img");
            img.src = result.url;

            this.dropBox.append(input);
            this.dropBox.append(img);
        }

        this.processQueue();
    }

    private areaClick()
    {
        this.launchFileUpload();
    }

    private constructor(name: string, dropbox: Element)
    {
        this.uploadName = name;
        this.dropBox = dropbox;

        this.dropBox.addEventListener("dragenter", (ev: Event) => this.areaDragEnter(ev));
        this.dropBox.addEventListener("dragleave", (ev: Event) => this.areaDragLeave(ev));
        this.dropBox.addEventListener("dragover", (ev: Event) => this.areaDragOver(ev));
        this.dropBox.addEventListener("drop", (ev: Event) => this.areaDrop(ev));

        this.dropBox.addEventListener("click", () => this.areaClick());
    }

    public static setupFromContainer(elem: HTMLElement)
    {
        const uploadName = elem.dataset.name;
        const dropBox = elem.querySelector(".drop-area");
        if (!dropBox || !uploadName)
        {
            return;
        }

        const uploader = new MultiUploader(uploadName, dropBox);
        AddUploader(uploader);
    }
}

function AddUploader(uploader: MultiUploader)
{
    controls.push(uploader);
}

function Initialise()
{
    const uploaders = document.getElementsByClassName("field-image-multi");
    for (let i = 0; i < uploaders.length; i++)
    {
        MultiUploader.setupFromContainer(uploaders[i] as HTMLElement);
    }
}

window.addEventListener("load", Initialise);